import React, { useState, useEffect } from 'react';

import './sidebarHeader.less';
import { Link, withRouter } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';

import { Tooltip } from 'antd';

import { classNames, isEmpty } from 'util/utils';

import sidebarCollectionActiveSvg from '../../assets/svg/sidebar/collection-active.svg';
import sidebarCollectionSvg from '../../assets/svg/sidebar/collection.svg';
import { commonLogout, LOCAL_STORAGE_VAR, KYC_STATUS } from '../../constants/Common';
import { GET_EXCLUSIVE_COLLECTION, GET_NOTIFICATIONS, MARK_AS_READ } from '../../constants/apiConstant';
import { eventBus } from '../../services/eventBus';
import UtilService from '../../services/util';
import IntlMessages from '../../util/IntlMessages';
import Logo from '../Header/Logo';
import OpenNotification from '../common/CommonButton/OpenNotification';
import CountBox, { NotificationCount } from '../common/CountBox';

const SidebarHeader = (props) => {
  const [notifications, setNotifications] = useState(0);
  const [collection, setCollection] = useState([]);
  const [collectionCount, setCollectionCount] = useState(0);

  const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);

  // const permissions = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
  const MarkAsRead = () => {
    const obj = {
      ...MARK_AS_READ,
      // request: {
      //   id: id,
      // },
    };

    UtilService.callApi(obj, (err, data) => {
      if (err) {
      } else if (data && data.code === 'OK') {
        if (data.data) {
          //setData(data.data.list);
          setNotifications(0);
        }
      } else {
        //setData(undefined);
      }
    });
  };

  const getNotifications = () => {
    const obj = {
      ...GET_NOTIFICATIONS,
    };

    UtilService.callApi(obj, (err, data) => {
      if (err) {
      } else if (data && data.code === 'OK') {
        if (data.data) {
          const total = data.data.unReadCount;
          setNotifications(total);
        }
      }
    });
  };

  useEffect(() => {
    eventBus.on('NOTIFICATION_COUNT', (data) => {
      getNotifications();
    });

    getNotifications();

    eventBus.on('NOTIFICATION_READ', (data) => {
      MarkAsRead();
    });

    return () => {
      eventBus.off('NOTIFICATION_COUNT');
      eventBus.off('NOTIFICATION_READ');
    };
  }, []);
  // let actionPerform = true
  // let messagePerform = true
  // let userData = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-user`)
  // if (userData && userData.account && !userData.account.isKycUploaded) {
  //   actionPerform = false
  //   messagePerform = 'Please upload your KYC documents to access this functionality.'
  // } else if (userData && userData.account && userData.account.isApproved !== KYC_STATUS.APPROVED) {
  //   messagePerform = 'You will access this functionality once your KYC is approved.'
  //   actionPerform = false
  //   // KYC under process
  // }
  // //  else if (userData && !userData.account) {
  // //   messagePerform = 'Please register yourself to access this functionality.'
  // //   actionPerform = false
  // // }

  const crnt_url = window.location.pathname;

  const fetchExclusiveCollection = async () => {
    const obj = {
      ...GET_EXCLUSIVE_COLLECTION,
    };

    UtilService.callApi(obj, (err, data) => {
      if (err) {
        setCollectionCount(0);
      } else if (data && data.code === 'OK') {
        if (data.data) {
          const list = data.data.list;
          const count = data.data.count;
          setCollectionCount(count && count);
          setCollection(list);
        }
      }
    });
  };

  useEffect(() => {
    fetchExclusiveCollection();
  }, [crnt_url]);

  return (
    <div className="sidebarHeader">
      <div>
        <Link to="/dashboard" className="sidebarLogo">
          <Logo />
        </Link>
        <ScrollArea
          speed={0.8}
          className="settingLeftScroll area sideBarScroll"
          contentClassName="content"
          smoothScrolling={Boolean}
          horizontal={false}
        >
          <div className="sidebarMenuList">
            <ul>
              <li className={`${crnt_url === `/dashboard` && `active`}`}>
                <Tooltip title={<IntlMessages id="app.dashboard" />} placement="rightTop">
                  <Link to="/dashboard">
                    <img alt="" className="defualtImg" src={require('../../assets/svg/sidebar/Dashboard.svg')} />
                    <img alt="" className="activeImg" src={require('../../assets/svg/sidebar/Dashboard-active.svg')} />
                    <span>
                      <IntlMessages id="app.dashboard" />
                    </span>
                  </Link>
                </Tooltip>
              </li>
              <li className={`${crnt_url === `/diamond-search` && `active`}`}>
                <Tooltip title={<IntlMessages id="app.DiamondSearch" />} placement="rightTop">
                  <Link to="/diamond-search">
                    <img alt="" className="defualtImg" src={require('../../assets/svg/sidebar/search.svg')} />
                    <img alt="" className="activeImg" src={require('../../assets/svg/sidebar/search-active.svg')} />
                    <span>
                      <IntlMessages id="app.DiamondSearch" />
                    </span>
                  </Link>
                </Tooltip>
              </li>
              {/* <li className={`${crnt_url === `/fancy-search` && `active`}`}>
                <Tooltip title="Colored Diamonds" placement="rightTop">
                  <Link to="/fancy-search">
                    <img
                      className="defualtImg"
                      alt=""
                      src={require("../../assets/svg/Dashboard/color-diamond.svg")}
                    />
                    <img
                      alt=""
                      className="activeImg"
                      src={require("../../assets/svg/Dashboard/theme-color-diamond.svg")}
                    />
                    <span>Colored Diamonds</span>
                  </Link>
                </Tooltip>
              </li> */}
              <li className={`${crnt_url === `/exclusive-diamonds` && `active`}`}>
                <Tooltip title={<IntlMessages id="app.Exclusive" />} placement="rightTop">
                  <Link to="/exclusive-diamonds">
                    <img className="defualtImg" alt="" src={require('../../assets/svg/Dashboard/color-diamond.svg')} />
                    <img
                      alt=""
                      className="activeImg"
                      src={require('../../assets/svg/Dashboard/theme-color-diamond.svg')}
                    />
                    <span>
                      <IntlMessages id="app.Exclusive" />
                      {/* Exclusive Diamonds */}
                    </span>
                  </Link>
                </Tooltip>
              </li>
              <li className={`${crnt_url === `/new-arrivals` && `active`}`}>
                <Tooltip title={<IntlMessages id="app.NewArrivals" />} placement="rightTop">
                  <Link to="/new-arrivals">
                    <img className="defualtImg" alt="" src={require('../../assets/svg/sidebar/new-arrivals.svg')} />
                    <img
                      className="activeImg"
                      alt=""
                      src={require('../../assets/svg/sidebar/theme-new-arrivals.svg')}
                    />
                    <span>
                      <IntlMessages id="app.newArrivalsDashboard" />
                    </span>
                  </Link>
                </Tooltip>
              </li>
              {/* <li className={`${crnt_url === `/recommended` && `active`}`}>
                <Tooltip title={<IntlMessages id="app.IAmLucky" />} placement="rightTop">
                  <Link to="/recommended">
                    <img className="defualtImg" alt="" src={require('../../assets/svg/sidebar/recommended.svg')} />
                    <img className="activeImg" alt="" src={require('../../assets/svg/sidebar/theme-recommended.svg')} />
                    <span>
                      <IntlMessages id="app.IAmLucky" />
                    </span>
                  </Link>
                </Tooltip>
              </li>
              <li className={`${crnt_url === `/feature-stone` && `active`}`}>
                <Tooltip title={<IntlMessages id="app.featuredStoneDashboard" />} placement="rightTop">
                  <Link to="/feature-stone">
                    <img className="defualtImg" alt="" src={require('../../assets/svg/sidebar/white-diamond.svg')} />
                    <img className="activeImg" alt="" src={require('../../assets/svg/sidebar/theme-diamond.svg')} />
                    <span>
                      <IntlMessages id="app.featuredStoneDashboard" />
                    </span>
                  </Link>
                </Tooltip>
              </li> */}
              {
                // USER_PERMIT?.COLLECTION?.view &&
                collection?.map?.((e) => {
                  return (
                    <li
                      key={e.id}
                      className={classNames([
                        `/collection${window.location.search}` === `/collection?${e.id}` && `active`,
                      ])}
                    >
                      <Tooltip title={<IntlMessages id={`${e ? e.name : 'Collection'}`} />} placement="rightTop">
                        <Link to={`/collection?${e.id}`}>
                          <img alt="" className="defualtImg img-65" src={sidebarCollectionSvg} />
                          <img className="activeImg img-65" src={sidebarCollectionActiveSvg} alt="" />
                          <span>
                            <IntlMessages id={`${e ? e.name : 'Collection'}`} />
                          </span>
                        </Link>
                      </Tooltip>
                    </li>
                  );
                })
              }

              {/* <li>
              <Tooltip title="New Goods" placement="rightTop">
                <a>
                  <img src={require("../../assets/svg/sidebar/new-good.svg")} />
                  <span>New Goods</span>
                </a>
              </Tooltip>
            </li> */}
              {/* <li>
                <Tooltip title="Upcoming" placement="rightTop">
                  <Link to="/upcoming">
                    <img src={require("../../assets/svg/sidebar/upcoming.svg")} />
                    <span>Upcoming</span>
                  </Link>
                </Tooltip>
              </li> */}
              <li className={`${crnt_url === `/notification` && `active`}`}>
                <Tooltip title={<IntlMessages id="app.notifications" />} placement="rightTop">
                  <NotificationCount count={notifications} />
                  <Link to="/notification">
                    <img className="defualtImg" alt="" src={require('../../assets/svg/sidebar/notification.svg')} />
                    <img
                      className="activeImg"
                      alt=""
                      src={require('../../assets/svg/sidebar/theme-notification.svg')}
                    />
                    <span>
                      <IntlMessages id="app.notifications" />
                    </span>
                  </Link>
                </Tooltip>
              </li>
              <li className={`${crnt_url === `/account/watchlist` && `active`}`}>
                <Tooltip title={<IntlMessages id="app.myAccount" />} placement="rightTop">
                  <Link to="/account/watchlist">
                    <img className="defualtImg" alt="" src={require('../../assets/svg/Header/user.svg')} />
                    <img className="activeImg" alt="" src={require('../../assets/svg/Header/theme-user.svg')} />
                    <span>
                      <IntlMessages id="app.myAccount" />
                    </span>
                  </Link>
                </Tooltip>
              </li>
            </ul>
          </div>
        </ScrollArea>
        <div className="sidebarMenuList sidebarListBottom">
          <ul>
            <li onClick={() => commonLogout()}>
              <Tooltip title={<IntlMessages id="app.Logout" />} placement="rightTop">
                <Link>
                  <img alt="" src={require('../../assets/svg/Header/logout.svg')} />
                  <span>
                    <IntlMessages id="app.Logout" />
                  </span>
                </Link>
              </Tooltip>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default withRouter(SidebarHeader);
