import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { ConnectedProgress } from 'components/ConnectedProgress';

import { store } from 'appRedux/store';
import Root from 'config/Root';

import 'styles/main.less';
import 'react-intl-tel-input/dist/main.css';

import 'assets/scss/global.css';
import 'components/common/Tab/tab.less';
import 'components/auth.less';

ReactDOM.render(
  <Provider store={store}>
    <ConnectedProgress />
    <Root />
  </Provider>,
  document.getElementById('root'),
);
