import React from 'react';
import './salesPerson.less';
const SalesPerson = ({seller}) => {
  return (
    <>
    <p className='title'>Sales Executive</p>
      <div className="salesPeronInnerDetail">
        <p className='seller-name'>{seller?.firstName} {seller?.lastName}</p>
        {!seller.email ? null : (
          <div className="salesPersonInnerItem">
            <img src={require('../../../assets/svg/email2.svg')} alt="icon" />
            <div className="detail">{seller.email}</div>
          </div>
        )}
        {!seller.mobile ? null : (
          <div className="salesPersonInnerItem">
            <img src={require('../../../assets/svg/phone2.svg')} alt="icon" />
            <div className="detail">{seller.mobile}</div>
          </div>
        )}
        {/* <div className="salesPersonInnerItem"> */}
        {/* {props.phone === '--' ? null : (
            <div
              onClick={() => {
                window.open(`https://wa.me/${props.phone}?text=I need help with `, '_blank');
              }}
              className="salesPersonIcon mr-5"
            >
              <img src={require('../../../assets/svg/Dashboard/whatsapp.svg')} alt="icon" />
            </div>
          )} */}
        {/* {props.skype === '--' ? null : (
            <div
              onClick={() => {
                window.open('https://web.skype.com/share?', '_blank');
              }}
              className="salesPersonIcon mr-5"
            >
              <img src={require('../../../assets/svg/Dashboard/skype.svg')} alt="icon" />
            </div>
          )}
          {props.phone === '--' ? null : (
            <div
              onClick={() => {
                window.open(`tel:${props.phone}`, '_blank');
              }}
              className="salesPersonIcon "
            >
              <img src={require('../../../assets/svg/Dashboard/chat.svg')} alt="icon" />
            </div>
          )} */}
        {/* <div className="salespersonInnerDetail">{props.phone}</div> */}
        {/* </div> */}
      </div>
    </>
  );
};
export default SalesPerson;