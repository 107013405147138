import React from 'react';

import { Dropdown } from 'antd';

import LangDropdown from 'components/Header/LangDropdown';
import SalesPerson from 'components/common/SalesPerson';

import UtilService from 'services/util';

import { LOCAL_STORAGE_VAR } from 'constants/Common';
import { GET_DASHBOARD_DATA } from 'constants/apiConstant';

import './headertop.less';
import AccountLinks from 'components/common/SalesPerson/AccountLinks';
import { useHistory, useLocation } from 'react-router-dom';
import { EventManager } from '../../../services/eventBus';
import TimeZoneDropdown from './TimeZoneDropdown';

const HeaderTop = () => {
  const [sales, SetsalesPerson] = React.useState(false);
  const [seller, setSeller] = React.useState([]);
  const history = useHistory();
  const location = useLocation();
  const [count, setCount] = React.useState(0);
  const [dropdownVisible, setDropdownVisible] = React.useState(false);

  const handleVisibleChange = (flag) => {
    setDropdownVisible(flag);
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  const salesDropdown = () => SetsalesPerson((sales) => !sales);

  const getDashBoardData = () => {
    const obj = {
      ...GET_DASHBOARD_DATA,
      request: {
        savedSearch: true,
        recentSearch: true,
        recentActivity: true,
        track: true,
        dashboardCount: true,
        shippingCount: true,
        seller: true,
        account: true,
        quoteDay: true,
      },
    };

    UtilService.callApi(obj, (err, data) => {
      if (err) {
        setSeller(undefined);
      } else if (data && data.code === 'OK') {
        if (data.data) {
          if (data.data.quoteDay) {
            localStorage.setItem(`${LOCAL_STORAGE_VAR}-quoteDay`, JSON.stringify(data.data.quoteDay));
          }
          setSeller(data.data.seller);
        }
      } else {
        setSeller(undefined);
      }
    });
  };

  React.useEffect(() => {
    getDashBoardData();
  }, []);

  React.useEffect(() => {
    const unlisten = EventManager.listen('@notifications/unReadCount', ({ unReadCount }) => {
      setCount(unReadCount);
    });
    return unlisten;
  }, []);

  return (
    <div className="headerTopBar d-flex align-items-center j-space-between">
      <div className="d-flex headerTopBlock align-items-center">
      {location.pathname.includes('/dashboard') && <p className="page-title">Dashboard</p>}
        <div className="partners">
          <img src={require('assets/img/header/fcrf.png')} alt="" />
          <img src={require('assets/img/header/rj.jpg')} alt="" />
          <img src={require('assets/img/header/namdia.png')} alt="" />
          <img src={require('assets/img/header/riotinto.png')} alt="" />
          <img src={require('assets/img/header/gemDiamonds.png')} alt="" />
        </div>
      </div>
      <div className="d-flex j-space-between align-items-center">
        <img src={require('assets/img/header/samir_logo_header.png')} className='mr-10 samir_header_right_logo' alt="" />
        <TimeZoneDropdown />
        <div className="breaker"></div>
        <div className="headerLanguage">
          <img src={require('assets/svg/Header/language.svg')} alt="" />
          <LangDropdown />
        </div>
        <div className="breaker"></div>
        <div className="header-right-action-wrapper">
          <div className="contact" onClick={() => history.push('/notification')}>
            {
              count > 0 ? <img src={require('assets/svg/Header/notification.svg')} alt="" /> : <img src={require('assets/svg/Header/notification2.svg')} alt="" />
            }
          </div>
          <Dropdown
            overlay={<AccountLinks closeDropdown={closeDropdown} />}
            trigger={['click']}
            visible={dropdownVisible}
            onVisibleChange={handleVisibleChange}
          >
            <div className="contact">
              <img src={require('assets/svg/Header/account.svg')} alt="" />
            </div>
          </Dropdown>
          {seller && seller.firstName && (
            <Dropdown
              overlay={
                <div className="salesPersonDropdown">
                  <SalesPerson seller={seller} />
                </div>
              }
              trigger={['click']}
            >
              <div className="contact">
                <img src={require('assets/svg/Header/contact.svg')} alt="" />
              </div>
              {/* <div className="SalesPersonWrapper" onClick={(e) => e.preventDefault()}>
              <>
                <div className="salesPersonProfile">
                  <img src={require('assets/img/header/user.jpg')} alt="profile" />
                </div>
                <div className="salesPersonDetail" onClick={salesDropdown}>
                  <span>{`${seller.firstName} ${seller.lastName}`}</span>
                  {sales ? (
                    <img className="buttonArrow" src={require('assets/svg/Dashboard/up-arrow-black.svg')} alt="" />
                  ) : (
                    <img className="buttonArrow" src={require('assets/svg/Dashboard/down-arrow.svg')} alt="" />
                  )}
                </div>
              </>
            </div> */}
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
};
export default HeaderTop;
