import { Storage } from 'services/Storage';

import { LOCAL_STORAGE_VAR } from 'constants/Common';

const initialState = { location: Storage.get(`${LOCAL_STORAGE_VAR}-locationDetail`, false) ?? {} };

export const LocationActionType = {
  SET_DETAIL: '@set/LocationDetail',
};

export const LocationAction = {
  SET_DETAILS: (payload) => ({ type: LocationActionType.SET_DETAIL, payload }),
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case LocationActionType.SET_DETAIL:
      return { ...state, location: payload };

    default:
      return state;
  }
};
