import debounce from 'lodash/debounce';

import { catchError, isArray, isNotEmpty, isFunction, isHTMLElement, isNumber, isString } from 'util/utils';

export class HeightUpdater {
  constructor(calculateFrom, setHeightTo, adjustment, isRelative) {
    this.setCalculateFrom(calculateFrom);
    this.setSetHeightTo(setHeightTo);
    this.setAdjustment(adjustment);
    this.setIsRelative(isRelative);
    this.resizeListener = false;
  }

  setCalculateFrom(calculateFrom = []) {
    if (isString(calculateFrom)) calculateFrom = calculateFrom.split(',').filter(isNotEmpty);
    this.calculateFrom = isArray(calculateFrom) ? calculateFrom : [calculateFrom];
  }

  setSetHeightTo(setHeightTo = []) {
    if (isString(setHeightTo)) setHeightTo = setHeightTo.split(',').filter(isNotEmpty);
    this.setHeightTo = isArray(setHeightTo) ? setHeightTo : [setHeightTo];
  }

  setAdjustment(adjustment = -0) {
    this.adjustment = adjustment ?? 0;
  }

  setIsRelative(isRelative = false) {
    this.isRelative = isRelative ?? false;
  }

  getHeight(element) {
    const height = element?.getBoundingClientRect?.()?.height ?? element?.offsetHeight;
    return isNumber(height) ? height : 0;
  }

  setHeight = debounce(() => {
    catchError(() => {
      document.body.style.height = '100vh';
      document.body.style.maxHeight = '100vh';
      document.body.style.minHeight = '100vh';

      if (!isArray(this.calculateFrom)) return;
      if (!isArray(this.setHeightTo)) return;

      const mapToHtmlElement = (item) => {
        if (isString(item)) return document.querySelector(item);
        if (isHTMLElement(item?.current)) return item.current;
        if (isHTMLElement(item)) return item;
      };

      const adjustment = isFunction(this.adjustment) ? this.adjustment() : this.adjustment;

      const height = this.calculateFrom
        .map(mapToHtmlElement)
        .filter(isHTMLElement)
        .reduce((totalHeight, container) => totalHeight + this.getHeight(container), -adjustment);

      const elementHeight = (() => {
        if (this.isRelative) return `calc(100% - ${height}px)`;
        return `${this.getHeight(document.body) - height}px`;
      })();

      const filteredSetHeightTo = this.setHeightTo.map(mapToHtmlElement).filter(isHTMLElement);

      filteredSetHeightTo.forEach((element) => {
        catchError(() => {
          if (element.style.height === elementHeight) return;
          element.style.height = elementHeight;
          element.style.visibility = 'visible';
        });
      });
    });
  }, 120);

  addListener = () => {
    window.addEventListener('resize', this.setHeight);
    window.addEventListener('DOMNodeInserted', this.setHeight);
    window.addEventListener('DOMNodeRemoved', this.setHeight);
    this.resizeListener = true;
  };

  removeListener = () => {
    window.removeEventListener('resize', this.setHeight);
    window.removeEventListener('DOMNodeInserted', this.setHeight);
    window.removeEventListener('DOMNodeRemoved', this.setHeight);
    this.resizeListener = false;
  };
}
