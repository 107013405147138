import React from 'react';
import IntlMessages from '../../../util/IntlMessages';
import { useHistory } from 'react-router-dom';

const AccountLinks = ({ closeDropdown }) => {
  const history = useHistory();

  const redirect = (path) => {
    history.push(path);
    closeDropdown()
  };

  return (
    <div className="account-links">
      <p className="link" onClick={() => redirect('/account/watchlist')}>
        <IntlMessages id="app.my-watchlist" />
      </p>
      <p className="link" onClick={() => redirect('/account/my-cart')}>
        <IntlMessages id="app.my-cart" />
      </p>
      <p className="link" onClick={() => redirect('/account/office-list')}>
        <IntlMessages id="app.office-list" />
      </p>
      <p className="link" onClick={() => redirect('/account/confirm-list')}>
        <IntlMessages id="app.order-request-list" />
      </p>
      <p className="link" onClick={() => redirect('/account/hold-list')}>
        <IntlMessages id="app.myHoldList" />
      </p>
      <p className="link" onClick={() => redirect('/account/enquiry')}>
        <IntlMessages id="app.my-enquiry" />
      </p>
      <p className="link" onClick={() => redirect('/account/saved-search')}>
        <IntlMessages id="app.saved-search" />
      </p>
      <p className="link" onClick={() => redirect('/account/profile')}>
        <IntlMessages id="app.my-account.PersonalDetails" />
      </p>
    </div>
  );
};

export default AccountLinks;
