import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { AuthService } from 'services/Auth';
import { Storage } from 'services/Storage';

import { history } from 'util/history';
import { useIsMobile, usePathname, useQueryParams } from 'util/hooks';
import { loadable } from 'util/loadable';
import { isEmpty, logError } from 'util/utils';

import App from '../App';

const Account = loadable(() => import('components/Account'));
const ChangePassword = loadable(() => import('components/Account/ChangePassword'));
const CompanyInformation = loadable(() => import('components/Account/CompanyInformation'));
const Compare = loadable(() => import('components/DiamondCompare'));
const Dashboard = loadable(() => import('components/Dashboard'));
const DiamondColumnListing = loadable(() => import('components/Account/ManageDiamondSequence'));
const DiamondList = loadable(() => import('components/DiamondList'));
const DiamondSearch = loadable(() => import('containers/DiamondSearch'));
const MobileAccount = loadable(() => import('components/Account/MobileAccount'));
const Notification = loadable(() => import('components/Notification'));
const NotificationDropdown = loadable(() => import('components/common/Header/NotificationDropdown'));
const Offer = loadable(() => import('components/Offer'));
const PersonalInformation = loadable(() => import('components/Account/PersonalInformation'));
const SavedSearch = loadable(() => import('components/SavedSearch'));

// function PermissionRoute(props) {
//   const { moduleType, ...forwardProps } = props;

//   const view = useSelector((store) => store.settings?.permissions?.[moduleType]?.view);

//   if (!view) return null;

//   return <Route {...forwardProps} />;
// }

function Routes() {
  const pathname = usePathname();
  const query = useQueryParams();

  const [isMobile] = useIsMobile();

  const token = useSelector((store) => store.auth?.token);
  const permissions = useSelector((store) => store.permission);

  const redirectPage = React.useCallback(() => {
    try {
      if (isEmpty(permissions)) AuthService.signOut(true);

      if (pathname === 'device' && !isEmpty(query.token)) Storage.set('token', query.token);

      if (isEmpty(token)) {
        const isTrusted = Storage.get('isTrusted', false) !== 'false';
        history.push(isTrusted ? 'signin' : 'verify-otp');
      }
    } catch (error) {
      logError(error);
    }
  }, [pathname, permissions, query.token, token]);

  React.useCallback(() => {
    redirectPage();
  }, [redirectPage]);

  return (
    <App>
      <Switch>
        <Route exact path="/dashboard" component={Dashboard} />

        <Route exact path="/diamond-search/:id?" component={DiamondSearch} />
        <Route exact path="/compare/:id?" component={Compare} />

        <Route exact path="/collection/:id?" component={DiamondList} />
        <Route exact path="/diamond-list/:id?" component={DiamondList} />
        <Route exact path="/exclusive-diamonds/:id?" component={DiamondList} />
        <Route exact path="/feature-stone/:id?" component={DiamondList} />
        <Route exact path="/match-pair/:id?" component={DiamondList} />
        <Route exact path="/new-arrivals" component={DiamondList} />
        <Route exact path="/recommended" component={DiamondList} />
        <Route exact path="/upcoming" component={DiamondList} />

        <Route exact path="/account/saved-search" component={isMobile ? SavedSearch : Account} />
        <Route exact path="/account/personal-information" component={isMobile ? PersonalInformation : Account} />
        <Route exact path="/account/company-profile" component={isMobile ? CompanyInformation : Account} />
        <Route exact path="/account/change-password" component={isMobile ? ChangePassword : Account} />
        <Route exact path="/account" component={isMobile ? MobileAccount : Account} />
        <Route exact path="/account/confirm-list" component={isMobile ? DiamondList : Account} />
        <Route exact path="/account/notes" component={isMobile ? DiamondList : Account} />
        <Route exact path="/account/office-list" component={isMobile ? DiamondList : Account} />
        <Route exact path="/account/profile/diamond-sequence" component={isMobile ? DiamondColumnListing : Account} />
        <Route exact path="/account/quote-list" component={isMobile ? Offer : Account} />
        <Route exact path="/account/watchlist" component={isMobile ? DiamondList : Account} />
        <Route exact path="/account/my-cart" component={isMobile ? DiamondList : Account} />
        <Route exact path="/account/enquiry" component={isMobile ? DiamondList : Account} />
        <Route exact path="/account/hold-list" component={isMobile ? DiamondList : Account} />
        <Route exact path="/account/saved-search" component={Account} />
        <Route exact path="/account/notification" component={Account} />
        <Route exact path="/account/reminder" component={Account} />
        <Route exact path="/account/profile" component={Account} />
        <Route exact path="/account/profile/manage-user" component={Account} />
        <Route exact path="/account/profile/manage-address" component={Account} />

        <Route exact path="/notification" component={isMobile ? NotificationDropdown : Notification} />

        <Redirect path="/" to="/dashboard" />
      </Switch>
    </App>
  );
}

export default Routes;
