import React, { Component } from 'react';

class Logo extends Component {
  render() {
    return (
      <>
        {this.props.themeLogo ? (
          <img
            width="100%"
            height="50px"
            style={{ objectFit: 'contain' }}
            src={require('../../assets/img/logo-sg.png')}
            alt="Samir Gems"
          />
        ) : (
          <img
            width="100%"
            height="50px"
            style={{ objectFit: 'contain' }}
            src={require('../../assets/img/logo-sg.png')}
            alt="Samir Gems"
          />
        )}
      </>
    );
  }
}

export default Logo;
