import React from 'react';

import loadableFn from '@loadable/component';

import { CircularProgress } from 'components/CircularProgress';

import { get, isString } from 'util/utils';

import { withErrorBoundary } from 'libraries/ReactErrorBoundary';

import { compose } from './hocs';

export function loadable(func, options) {
  return compose(withErrorBoundary())(
    loadableFn(func, {
      fallback: <CircularProgress />,
      ...(isString(options) ? { resolveComponent: (c) => get(c, options) } : { ...options }),
    }),
  );
}

export default loadable;
