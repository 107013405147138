import * as React from 'react';

import { Button } from 'antd';

const changedArray = (a = [], b = []) => a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]));
const initialState = { error: null };

const ErrorFallback = React.memo(({ error, info, resetErrorBoundary }) => {
  console.info(error?.stack?.split?.('\n')?.[0], info?.componentStack);

  return (
    <div role="alert" STYLE="padding: 1.5rem;">
      <h2>Something went wrong</h2>
      <br />
      <Button type="primary" htmlType="button" onClick={resetErrorBoundary}>
        Try again
      </Button>
    </div>
  );
});

export class ErrorBoundary extends React.Component {
  constructor() {
    super(...arguments);
    this.state = initialState;

    this.resetErrorBoundary = (...args) => {
      void this.props.onReset?.(...args);
      this.reset();
    };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  reset() {
    this.setState(initialState);
  }

  componentDidCatch(error, info) {
    this.setState({ info });
    void this.props.onError?.(error, info);
  }

  componentDidUpdate(prevProps, prevState) {
    const { error } = this.state;
    const { resetKeys } = this.props;

    if (error !== null && prevState.error !== null && changedArray(prevProps.resetKeys, resetKeys)) {
      void this.props.onResetKeysChange?.(prevProps.resetKeys, resetKeys);
      this.reset();
    }
  }

  render() {
    const { error, info } = this.state;
    const { fallbackRender, FallbackComponent, fallback } = this.props;

    if (error !== null) {
      const props = {
        error,
        info,
        resetErrorBoundary: this.resetErrorBoundary,
      };

      if (React.isValidElement(fallback)) {
        return fallback;
      } else if (typeof fallbackRender === 'function') {
        return fallbackRender(props);
      } else if (FallbackComponent) {
        return React.createElement(FallbackComponent, { ...props });
      } else {
        throw new Error('react-error-boundary requires either a fallback, fallbackRender, or FallbackComponent prop');
      }
    }
    return this.props.children;
  }
}

ErrorBoundary.defaultProps = {
  FallbackComponent: ErrorFallback,
};
