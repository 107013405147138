import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { history } from 'util/history';

import reducers from '../reducers';

const composeWithDevTools =
  process.env.REACT_APP_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({ trace: true, traceLimit: 25 })
    : compose;

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history))));
