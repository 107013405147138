import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import getData from '../common/svgSidebar';

import _ from 'lodash';

import 'react-tippy/dist/tippy.css';

import { AuthService } from 'services/Auth';

import { commonLogout } from '../../constants/Common';
import IntlMessages from '../../util/IntlMessages';
import HeaderStrip from '../common/Header/HeaderStrip';

class MobileSidebar extends Component {
  constructor() {
    super();

    this.state = {
      visible: true,
      visible1: false,
      visible2: false,
      visible3: false,
    };
  }

  submenu = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  submenu1 = () => {
    this.setState({
      visible1: !this.state.visible1,
    });
  };

  submenu2 = () => {
    this.setState({
      visible2: !this.state.visible2,
    });
  };

  submenu3 = () => {
    this.setState({
      visible3: !this.state.visible3,
    });
  };

  render() {
    const crnt_url = window.location.pathname;
    const permissionData = this.props.commonData.pagePermission();
    const sidebarSvgFiles = getData(permissionData);
    const cuIndex = _.findIndex(sidebarSvgFiles, { url: crnt_url });
    return (
      <div className="headerMenuBox">
        <div className="headerMeniInner">
          <HeaderStrip />
          <ul id="links" className="">
            <li>
              <Link className={`menuItem ${crnt_url === `/dashboard` && `active`}`} to="/dashboard">
                <IntlMessages id="app.dashboard" />
              </Link>
            </li>
            <li>
              <Link className={`menuItem ${crnt_url === `/diamond-search` && `active`}`} to="/diamond-search">
                <IntlMessages id="app.DiamondSearch" />
              </Link>
            </li>
            {/* <li>
              <Link className={`menuItem ${crnt_url === `/diamond-search` && `active`}`} to="/diamond-search">
                Colored Diamond
              </Link>
            </li> */}
            <li>
              <Link className={`menuItem ${crnt_url === `/exclusive-diamonds` && `active`}`} to="/exclusive-diamonds">
                <IntlMessages id="app.Exclusive" />
              </Link>
            </li>
            <li>
              <Link className={`menuItem ${crnt_url === `/new-arrivals` && `active`}`} to="/new-arrivals">
                <IntlMessages id="app.newArrival" />
              </Link>
            </li>
            {/* <li>
              <Link className={`menuItem ${crnt_url === `/recommended` && `active`}`} to="/recommended">
                Recommended
              </Link>
            </li> */}
            <li>
              <Link className={`menuItem ${crnt_url === `/notification` && `active`}`} to="/notification">
                <IntlMessages id="app.notifications" />
              </Link>
            </li>
            {/* <li>
              <Link className={`menuItem ${crnt_url === `/upcoming` && `active`}`} to="/upcoming">
                <IntlMessages id="app.Upcoming" />
              </Link>
            </li> */}
            <li>
              <Link
                className={`menuItem ${crnt_url === `/account/saved-search` && `active`}`}
                to="/account/saved-search"
              >
                <IntlMessages id="app.savedsearchtitle" />
              </Link>
            </li>
            <li>
              <Link className={`menuItem ${crnt_url === `/account` && `active`}`} to="/account">
                <IntlMessages id="app.myAccount" />
              </Link>
            </li>
            <li onClick={AuthService.signOut}>
              <a>
                <IntlMessages id="app.Logout" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

// export default withRouter(Sidebar);
const mapStateToProps = function (props) {
  return props;
};

export default connect(mapStateToProps)(withRouter(MobileSidebar));
