import { Storage } from 'services/Storage';

import { callApi } from 'util/call-api';
import { getUserName, isEmpty } from 'util/utils';

import { CREATE_ANALYTICS } from 'constants/apiConstant';

export { getUserName as getFullName } from 'util/utils';

export const track = (data) => {
  if (isEmpty(Storage.get('token'))) return;
  const [page, section, action] = data.split('/');
  return callApi({ ...CREATE_ANALYTICS, request: { page, section, action } });
};

export default {
  callApi,
  callApiAsync: callApi,
  getFullName: getUserName,
  getLocalStorageItem: (key) => Storage.get(key),
  setLocalStorageItem: (value, key) => Storage.set(key, value),
};
