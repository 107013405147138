import React from 'react';

import { GridHeightContainer } from 'components/GridHeightContainer';
import MobileHeader from 'components/MobileHeader/index';
import SidebarHeader from 'components/SidebarHeader';

import { useIsMobile } from 'util/hooks';

import HeaderTop from './components/Header/HeaderTop';

const App = (props) => {
  const { children } = props;
  const [isMobile] = useIsMobile();

  const topHeader = React.useMemo(() => {
    return isMobile ? null : <HeaderTop />;
  }, [isMobile]);

  const subHeader = React.useMemo(() => {
    return isMobile ? <MobileHeader /> : <SidebarHeader />;
  }, [isMobile]);

  return (
    <React.Fragment key="App">
      <GridHeightContainer className="d-flex flex-wrap pageWrapperBlock">
        {subHeader}
        <GridHeightContainer className="content-area">
          {topHeader}
          <GridHeightContainer subtractFrom="#root .pageWrapperBlock .headerTopBar">{children}</GridHeightContainer>
        </GridHeightContainer>
      </GridHeightContainer>
    </React.Fragment>
  );
};

export default App;
