import React, { Component } from 'react';

import './headerstrip.less';
import '../../SalesPerson/salesPerson.less';
import { Select, Dropdown } from 'antd';

import LangDropdown from './LangDropdown';

import IntlMessages from '../../../../util/IntlMessages';
import SalesPerson from '../../SalesPerson';

const time = new Date().toLocaleString();
const { Option } = Select;

const HeaderStirpItem = (props) => {
  return (
    <div className="headerStripItem">
      <span>{props.title}</span>
      <span className="headerStripValue">{props.value}</span>
    </div>
  );
};

class HeaderStrip extends Component {
  constructor(props) {
    super(props);
    this.state = { date: new Date(), salesOption: false };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  salesDropdown = () => {
    this.setState({
      salesOption: !this.state.salesOption,
    });
  };

  tick() {
    this.setState({
      date: new Date(),
    });
  }

  render() {
    return (
      <>
        <div className={`HeaderStripBlock ${this.props.className}`}>
          {/* <div className="HeaderStripLeft"> */}
          {/* <HeaderStirpItem title="Dollar Rate" value="73.75" /> */}
          {/* <HeaderStirpItem
              title={<IntlMessages id="app.AdvancePayment" />}
              value="73.75"
            />
            <HeaderStirpItem title="COD" value="73.75" /> */}
          {/* <HeaderStirpItem title={<IntlMessages id="app.Time" />} value={this.state.date.toLocaleTimeString()} /> */}
          {/* </div> */}
          <div className="HeaderStripRight">
            <div className="SelectLanguage">
              <LangDropdown />
            </div>
            {/* <Dropdown overlay={ <div className="salesPersonDropdown">
                  <SalesPerson/>
                </div>} trigger={['click']}>
                <div className="SalesPersonWrapper" onClick={e => e.preventDefault()}>
                <>
                  <div className="salesPersonProfile">
                    <img src={require("../../../../assets/img/header/user.jpg")} alt="profile"/>
                  </div>
                  <div className="salesPersonDetail" onClick={this.salesDropdown}>
                      <span>Vishal Virani</span>
                      {this.state.salesOption ?
                        <>
                          <img className="buttonArrow" src={require("../../../../assets/svg/Dashboard/up-arrow.svg")} alt=""/>
                        </>
                        :
                        <>
                          <img className="buttonArrow" src={require("../../../../assets/svg/Dashboard/down-arrow-white.svg")} alt=""/>
                        </>
                        }
                  </div>
                  </>
                </div>
            </Dropdown> */}
          </div>
        </div>
        {/* <div className="downArrow" onClick={this.props.onClick}>
          <img
            className="closeImage"
            src={require("../../../../assets/svg/Header/down-arrow.svg")}
            alt=""
          />
        </div> */}
      </>
    );
  }
}
export default HeaderStrip;
