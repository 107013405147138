import React from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect, BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { loadable } from 'util/loadable';
import { isEmpty } from 'util/utils';

import AppLocale from 'lngProvider';

import Routes from './Routes';

const ForgotPassword = loadable(() => import('components/Auth/ForgotPassword'));
const ResetPassword = loadable(() => import('components/Auth/ResetPassword'));
const DNA = loadable(() => import('components/DNA'));
const DiamondDetail = loadable(() => import('components/DiamondDetail'));
const EmailVerify = loadable(() => import('components/EmailVerify'));
const GuestLogin = loadable(() => import('components/GuestLogin'));
const SignIn = loadable(() => import('components/SignIn'));
const SignUp = loadable(() => import('components/SignUp'));
const ThankYouAfterSignup = loadable(() => import('components/ThankYou'));
const VerifyDevice = loadable(() => import('components/VerifyDevice'));
const VerifyOtp = loadable(() => import('components/VerifyOtp'));
const ContactUs = loadable(() => import('components/common/ContactUs'));

const SignOutRoute = React.memo(({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.auth?.token);
  return (
    <Route {...rest} render={(props) => (isEmpty(token) ? <Component {...props} /> : <Redirect to={'/dashboard'} />)} />
  );
});
SignOutRoute.displayName = 'SignOutRoute';

const SignInRoute = React.memo(({ component: Component, ...rest }) => {
  const token = useSelector((state) => state.auth?.token);
  return (
    <Route {...rest} render={(props) => (!isEmpty(token) ? <Component {...props} /> : <Redirect to={'/signin'} />)} />
  );
});
SignOutRoute.displayName = 'SignOutRoute';

function Root() {
  const currentLocale = useSelector((state) => AppLocale[state.commonData?.locale?.locale ?? 'en']);

  return (
    <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
      <Router>
        <Switch>
          <SignOutRoute exact path="/signin" component={SignIn} />
          <Route exact path="/verify-otp" component={VerifyOtp} />
          <SignOutRoute exact path="/signup" component={SignUp} />
          <SignOutRoute exact path="/device/signup" component={SignUp} />
          <SignOutRoute exact path="/guest-login" component={GuestLogin} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password/:token" component={ResetPassword} />
          <Route exact path="/thank-you" component={ThankYouAfterSignup} />
          <Route exact path="/verify-email/:id?" component={EmailVerify} />
          <Route exact path="/verify-device/:id?" component={VerifyDevice} />
          <Route exact path="/contact-us" component={ContactUs} />
          <Route exact path="/device/contact-us" component={ContactUs} />
          {/* <Route exact path="/xrays" component={XRAY} /> */}
          {/* <Route exact path="/DiamFTC" component={FTC} /> */}
          <Route exact path="/diamond-detail/:id" component={DiamondDetail} />
          {/* <Route exact path="/dna/:id" component={DNA} /> */}

          <SignInRoute path="/" component={Routes} />
          <Redirect path="/" to="/signin" />
        </Switch>
      </Router>
    </IntlProvider>
  );
}

export default Root;
