const prefix = '/web/v1';

export const API_COLUMN_SETTING = {
  url: `${prefix}/grid/get-columns`,
  method: 'post',
  showLoader: true,
};

export const COMMENT_UPSERT = {
  method: 'post',
  url: '/admin/diamond-comment/upsert',
  showLoader: true,
};

export const DIAMOND_TRACK_DELETE = {
  method: 'post',
  url: '/admin/diamond-track/delete',
  showLoader: true,
};

export const NOTE_DELETE = {
  method: 'post',
  url: `${prefix}/diamond-comment/delete`,
  showLoader: true,
};

export const HIT_COUNTER = {
  method: 'post',
  url: '/admin/diamond/hit-counter',
  showLoader: true,
};

export const GET_DASHBOARD_DATA = {
  method: 'post',
  url: `${prefix}/user/dashboard`,
  showLoader: true,
};

export const GET_MYPURCHASE_DATA = {
  method: 'post',
  url: `${prefix}/memo/paginate`,
  showLoader: true,
};

export const SEARCH_DIAMOND_LIST = {
  method: 'post',
  url: `${prefix}/diamond/paginate`,
  //showLoader: true,
};

export const SEARCH_LIST = {
  method: 'post',
  url: `${prefix}/diamond/search/list`,
  showLoader: true,
};

export const CREATE_SAVED_SEARCH = {
  method: 'post',
  url: `${prefix}/diamond/search/upsert`,
  showLoader: true,
};

export const CREATE_MATCH_PAIR = {
  method: 'post',
  url: `${prefix}/match-pair/diamond/filter`,
  showLoader: true,
};

// export const BID_CONFIG = {
//   method: 'get',
//   url: `${prefix}/diamond-bid/dynamic-bid-config`,
// };

export const BID_PAGINATE = {
  method: 'post',
  url: `${prefix}/diamond-bid/paginate`,
  showLoader: true,
};

export const BID_DELETE = {
  method: 'post',
  url: `${prefix}/diamond-bid/delete`,
  showLoader: true,
};

export const SELECT_SEARCH = {
  method: 'post',
  url: `${prefix}/diamond/search/list`,
  showLoader: true,
};

export const CREATE_DEMAND = {
  method: 'post',
  url: `${prefix}/diamond/search/upsert`,
  showLoader: true,
};

export const FETCH_CONFIRM_STONE = {
  url: `${prefix}/memo/paginate`,
  method: 'post',
  showLoader: false,
};

export const FETCH_DIAMOND_TRACK = {
  method: 'post',
  url: `${prefix}/diamond-track/paginate`,
  showLoader: false,
};

export const FETCH_NOTES = {
  method: 'post',
  url: `${prefix}/diamond-comment/by-user`,
  showLoader: true,
};

export const CREATE_TRACK = {
  method: 'post',
  url: `${prefix}/diamond-track/create`,
  showLoader: true,
};

export const UPDATE_TRACK = {
  method: 'post',
  url: `${prefix}/diamond-track/update`,
  showLoader: true,
};

export const PRINT_PDF = {
  method: 'post',
  url: `${prefix}/diamond/pdf`,
  showLoader: true,
};
export const GET_EXCLUSIVE_COLLECTION = {
  method: 'post',
  url: `${prefix}/diamond/exclusive-search/list`,
  showLoader: true,
};

export const EXPORT_EXCEL = {
  method: 'post',
  url: `${prefix}/diamond/excel`,
  showLoader: true,
};

export const CONFIRM_STONE = {
  method: 'post',
  url: `${prefix}/diamond-confirm/request`,
  showLoader: true,
};

export const DELETE_TRACK = {
  method: 'post',
  url: `${prefix}/diamond-track/delete`,
  showLoader: true,
};

export const NOTE_UPSERT = {
  method: 'post',
  url: '/web/v1/diamond-comment/upsert',
  showLoader: true,
};

export const CREATE_BID = {
  method: 'post',
  url: `${prefix}/diamond-bid/create`,
  showLoader: true,
};

export const UPDATE_BID = {
  method: 'post',
  url: `${prefix}/diamond-bid/update`,
  showLoader: true,
};

export const SEND_EMAIL_XRAY = {
  method: 'post',
  url: `${prefix}/diamond/x-ray-mail`,
  showLoader: true,
};

export const VIEW_DIAMOND = {
  method: 'post',
  url: '/web/v1/diamond/view',
  showLoader: true,
};

export const SEARCH_STONES_BY_ID = {
  method: 'post',
  url: `${prefix}/diamond/search/by-id`,
  showLoader: true,
};

export const DELETE_SEARCH = {
  method: 'post',
  url: '/web/v1/diamond/search/delete',
  showLoader: true,
};

export const VERIFY_FINGER_PRINT = {
  method: 'post',
  url: `${prefix}/verifyfingerprint`,
  showLoader: true,
};
// resend finger print otp
export const RESENT_FP_OTP = {
  method: 'post',
  url: `${prefix}/resend-fp-otp`,
  showLoader: true,
};
// Search Diamonds by id
export const DIAMOND_DATA_BY_ID = {
  method: 'post',
  url: `${prefix}/diamond/search/by-id`,
  showLoader: true,
};
// TO Verify E-mail
export const VERIFY_MAIL = {
  method: 'post',
  url: `${prefix}/auth/verify-email`,
  showLoader: true,
};
export const GET_BANNERS = {
  method: 'post',
  url: `${prefix}/banner/paginate`,
  showLoader: true,
};
// Delete Searched Diamond
export const DELETE_SEARCHED_DIAMOND = {
  method: 'post',
  url: `${prefix}/diamond/search/delete`,
  showLoader: true,
};
// Get Quick Search Data
export const QUICK_SEARCH = {
  method: 'post',
  url: `${prefix}/diamond/quick-search`,
  showLoader: true,
};

export const GET_DIAMOND_DATA = {
  method: 'post',
  url: `${prefix}/diamond/paginate`,
  showLoader: true,
};

export const RESET_PWD_BYUSER = {
  method: 'post',
  url: `${prefix}/auth/reset-password-by-user`,
  showLoader: true,
};

export const GET_COUNTRY_LIST = {
  method: 'post',
  url: `${prefix}/country/paginate`,
  showLoader: true,
};

export const GET_STATE_LIST = {
  method: 'post',
  url: `${prefix}/state/paginate`,
  showLoader: true,
};

export const GET_CITY_LIST = {
  method: 'post',
  url: `${prefix}/city/paginate`,
  showLoader: true,
};

export const GET_USER_PROFILE = {
  method: 'post',
  url: `${prefix}/user/profile`,
  showLoader: true,
};

export const UPDATE_USER_PROFILE = {
  method: 'post',
  url: `${prefix}/account/update`,
  showLoader: true,
};

export const UPLOAD_FILE = {
  method: 'post',
  url: `${prefix}/upload-file`,
  showLoader: true,
};
export const USER_SETTING_LIST = {
  method: 'post',
  url: `${prefix}/user-setting/paginate`,
  showLoader: true,
};
export const USER_SETTING_UPSERT = {
  method: 'post',
  url: `${prefix}/user-setting/upsert`,
  showLoader: true,
};

export const FETCH_USER = {
  method: 'post',
  url: `${prefix}/user/view`,
  showLoader: true,
};
export const ADMIN_UPDATE_USER = {
  method: 'put',
  url: `${prefix}/user/update`,
  showLoader: true,
};

export const FORGOT_PASSWORD = {
  method: 'post',
  url: `${prefix}/auth/forgot-password`,
  showLoader: true,
};

export const LOGIN_USER = {
  method: 'post',
  url: `${prefix}/auth/login`,
  showLoader: true,
};
export const GUEST_LOGIN_USER = {
  method: 'post',
  url: `${prefix}/guest/auth/login`,
  showLoader: true,
};
// Resend Verification Mail
export const RESEND_VERIFY_MAIL = {
  method: 'post',
  url: `${prefix}/user/resend-verification-email`,
  showLoader: true,
};

export const UPDATE_USER_TOKEN = {
  method: 'post',
  url: `${prefix}/auth/update-token`,
  showLoader: true,
};
export const PWD_RESET = {
  method: 'post',
  url: `${prefix}/auth/reset-password`,
  showLoader: true,
};

export const FETCH_SELLERS = {
  method: 'post',
  url: `${prefix}/register/sellers`,
  showLoader: true,
};
// export const SIGNUP_USER_FRONT = {
//   method: "post",
//   url: `${prefix}/front/register`,
// };
export const SIGNUP_USER_FRONT = {
  method: 'post',
  url: `${prefix}/register/register`,
  showLoader: true,
};
export const DIAMOND_XRAY_MAIL = {
  method: 'post',
  url: `${prefix}/diamond/x-ray-mail`,
  showLoader: true,
};

export const CREATE_ANALYTICS = {
  method: 'post',
  url: `${prefix}/analytics/create`,
  showLoader: true,
};

export const USER_LOGOUT = {
  method: 'post',
  url: `${prefix}/auth/logout`,
  showLoader: true,
};

export const FEATURE_STONE = {
  method: 'post',
  url: `${prefix}/featuredStone/paginate`,
  showLoader: true,
};

export const DOWNLOAD_ZIP = {
  method: 'post',
  url: `${prefix}/diamond/download-zip`,
  showLoader: true,
};

export const DOWNLOAD_CERTIFICATE = {
  method: 'post',
  url: `${prefix}/diamond/download-certificate`,
  showLoader: true,
};

export const DOWNLOAD_FILE = {
  method: 'post',
  url: `${prefix}/download-file`,
  showLoader: true,
};

export const DIAMOND_FTC = {
  method: 'post',
  url: `${prefix}/diamond/diamond-ftc`,
  showLoader: true,
};

export const CREATE_SCHEDULE = {
  method: 'post',
  url: `${prefix}/cabin-schedule/create`,
  showLoader: true,
};
export const GET_NOTIFICATIONS = {
  method: 'post',
  url: `${prefix}/notification/list`,
  //showLoader: true,
};

export const GET_EVENTS = {
  method: 'post',
  url: `${prefix}/event/paginate`,
  showLoader: true,
};

export const PROJECT_SETTING = {
  method: 'post',
  url: `${prefix}/project-setting/view`,
  showLoader: true,
};

export const MARK_AS_READ = {
  method: 'post',
  url: `${prefix}/notification/markAsRead`,
  showLoader: true,
};

export const GET_SLOTS = {
  method: 'post',
  url: `${prefix}/cabin-slot/paginate`,
  showLoader: true,
};

export const FETCH_OFFICE_DIAMOND = {
  method: 'post',
  url: `${prefix}/cabin-schedule/list`,
  showLoader: false,
};

export const GET_TRACK_SHIPMENTS = {
  method: 'post',
  url: `${prefix}/shipping-detail/paginate`,
  showLoader: true,
};

export const GET_SHIPMENT_DETAILS = {
  method: 'post',
  url: `${prefix}/shipping-detail`,
  showLoader: true,
};

export const GET_MASTERS = {
  method: 'post',
  url: `${prefix}/master/list-by-code-no-auth`,
  showLoader: true,
};

export const DELETE_OFFICE_DIAMOND = {
  method: 'post',
  url: `${prefix}/cabin-schedule/destroyDiamondAndSlot`,
  showLoader: true,
};

export const GET_STONEID_CERTI = {
  method: 'post',
  url: `${prefix}/diamond/reportno/paginate`,
  showLoader: true,
};

export const BLOCK_MASTERS = {
  method: 'post',
  url: `/admin/v1/diamond-block/master/paginate`,
};

export const ADD_HOLD = {
  method: 'post',
  url: `${prefix}/diamond-block/create`,
};

export const FETCH_DIAMOND_BLOCK = {
  method: 'post',
  url: `${prefix}/diamond-block/paginate`,
};

export const GET_NEW_ARRIVAL_DAYS = {
  method: 'post',
  url: '/admin/v1/setting/view',
};

export const SAVE_COLUMN_SEQUENCE = {
  method:'post',
  url: `${prefix}/grid/save-column-sequence`,
}