import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Select } from 'antd';
import ipapi from 'ipapi.co';
import _ from 'lodash';

import languageData from 'constants/languageData';

import { switchLanguage } from 'appRedux/actions/Common';
import SelectIcon from 'assets/svg/Header/SelectIcon';

const { Option } = Select;

class LangDropdown extends Component {
  state = {
    country_code: '',
    langDefaultVal: (this.props.commonData.locale && this.props.commonData.locale.name) || languageData[0].name,
  };

  componentDidMount() {
    const self = this;

    const callback = function (res) {
      if (res && res.country_code) {
        const findCountry = _.find(languageData, { country_code: res.country_code });
        const setLanguage = {
          country_code: res.country_code,
        };

        if (findCountry && findCountry.languageId) {
          setLanguage.langDefaultVal = findCountry.name;
          self.props.switchLanguage(findCountry.languageId);
        }
        self.setState(setLanguage);
      }
    };

    ipapi.location(callback);
  }

  render() {
    return (
        <Select
          defaultValue={this.state.langDefaultVal}
          onChange={this.props.switchLanguage}
          suffixIcon={<SelectIcon />}
          className='header_language_selection'
        >
          {languageData.map((language) => (
            <Option key={language.languageId} value={language.languageId}>
              {language.name}
            </Option>
          ))}
        </Select>
    );
  }
}

export default connect(
  (state) => ({
    commonData: state.commonData,
  }),
  { switchLanguage },
)(LangDropdown);
