import React from 'react';
import { Select } from 'antd';
import moment from 'moment-timezone';
import SelectIcon from 'assets/svg/Header/SelectIcon';

const { Option } = Select;

const timezoneMapping = {
  'Asia/Kolkata': 'India',
  'Asia/Hong_Kong': 'Hong Kong',
  'Europe/Brussels': 'Belgium',
  'America/New_York': 'USA',
  'Asia/Dubai': 'Dubai'
};

const timezones = Object.keys(timezoneMapping);

const TimeZoneDropdown = () => {
  const initialTimezone = moment.tz.guess();
  const [selectedTimezone, setSelectedTimezone] = React.useState(
    timezones.includes(initialTimezone) ? initialTimezone : 'Asia/Kolkata',
  );
  const [currentTime, setCurrentTime] = React.useState(() => moment().tz(moment.tz.guess()));

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().tz(selectedTimezone)); // Update current time based on selected timezone
    }, 1000);

    return () => clearInterval(intervalId);
  }, [selectedTimezone]);

  const handleTimezoneChange = (value) => {
    setSelectedTimezone(value);
  };

  return (
    <>
      <Select
        value={selectedTimezone}
        style={{ width: 150 }}
        onChange={handleTimezoneChange}
        suffixIcon={<SelectIcon />}
      >
        {timezones.map((timezone) => (
          <Option key={timezone} value={timezone}>
            {timezoneMapping[timezone]}
          </Option>
        ))}
      </Select>
      <p>{currentTime.format('DD MMM YYYY, h:mm a (z)')}</p>
    </>
  );
};

export default TimeZoneDropdown;
