import React from 'react';
import IntlMessages from '../../util/IntlMessages';

export default function getData(permissionData) {
  let sidebarArr = [];
  sidebarArr.push({
    name: <IntlMessages id="app.DiamondSearch" />,
    url: '/diamond-search',
    subMenu: true,
  });
  sidebarArr.push({
    name: 'Colored Diamonds',
    url: '/fancy-search',
    subMenu1: true,
  });
  sidebarArr.push({
    name: <IntlMessages id="app.Exclusive" />,
    url: '/diamond-search',
    subMenu2: true,
  });

  sidebarArr.push({
    name: 'New Arrivals',
    url: '/diamond-search',
    subMenu3: true,
  });
  // sidebarArr.push({
  //   name: <IntlMessages id="app.Upcoming" />,
  //   url: "/upcoming",
  //   subMenu4: true,
  // });
  return sidebarArr;
}
