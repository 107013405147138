import React from 'react';

const SelectIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
      <path d="M2.19236 4.5L9.80786 4.5L6.00011 8.30775L2.19236 4.5Z" fill="#707A8F" />
    </svg>
  );
};

export default SelectIcon;
