import React from 'react';

import PropTypes from 'prop-types';

import { isNotEmpty } from 'util/utils';

import { HeightUpdater } from './HeightUpdater';

function Component(props, forwardedRef) {
  const { tag = 'div', subtractFrom, adjustment, isRelative, hide, ...restProps } = props;

  const ref = React.useRef();
  React.useImperativeHandle(forwardedRef, () => ref.current);

  const heightUpdater = React.useMemo(() => {
    return new HeightUpdater(subtractFrom, ref, adjustment, isRelative);
  }, [ref, subtractFrom, adjustment, isRelative]);

  const setDefaultStyles = React.useCallback(() => {
    if (ref.current?.style) {
      const defaultStyle = { height: 'auto', overflow: 'overlay', visibility: 'hidden' };

      ref.current.classList.add('overlay-scroll');
      ref.current.style.overflow = defaultStyle.overflow;
      ref.current.style.overflow = ref.current.style.overflow || 'auto';
      ref.current.style.height = ref.current.style.height || defaultStyle.height;
      ref.current.style.visibility = ref.current.style.visibility || defaultStyle.visibility;
    }
  }, []);

  React.useEffect(() => {
    heightUpdater.setHeight();
    heightUpdater.addListener();

    return () => {
      heightUpdater.removeListener();
    };
  }, [heightUpdater, setDefaultStyles]);

  React.useLayoutEffect(() => {
    setDefaultStyles();
    heightUpdater.setHeight();
  });

  if (hide) return null;

  return React.createElement(tag, { ref, 'grid-height-container': '', ...restProps });
}

export const GridHeightContainer = React.memo(React.forwardRef(Component));
GridHeightContainer.selectors = (list) => list.filter(isNotEmpty).join(',');

GridHeightContainer.displayName = 'GridHeightContainer';

GridHeightContainer.propTypes = {
  tag: PropTypes.string,
  subtractFrom: PropTypes.any,
  adjustment: PropTypes.number,
  isRelative: PropTypes.bool,
};

export default GridHeightContainer;
