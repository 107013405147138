import React from 'react';
import UtilService from '../../../services/util';
import { GET_NOTIFICATIONS } from '../../../constants/apiConstant';
import './countBox.less';
import { EventManager } from '../../../services/eventBus';

const CountBox = React.memo(function CountBox({ count }) {
  return Boolean(count) && <div className="countBox">{count}</div>;
});

export const NotificationCount = React.memo(function NotificationCount(props) {
  const [count, setCount] = React.useState(props.count);

  // const getCount = React.useCallback(async () => {
  //   if (props.count >= 0) return setCount(props.count);

  //   const [, res] = await UtilService.callApiAsync({
  //     ...GET_NOTIFICATIONS,
  //     request: { page: 1, limit: 10 },
  //   });

  //   if (res && res.code === 'OK' && res.data) {
  //     setCount(res.data.unReadCount);
  //   }
  // }, [props.count]);

  // React.useEffect(() => {
  //   getCount();
  // }, [getCount]);

  React.useEffect(() => {
    const unlisten = EventManager.listen('@notifications/unReadCount', ({ unReadCount }) => {
      setCount(unReadCount);
    });
    return unlisten;
  }, []);

  return <CountBox count={count} />;
});

export default CountBox;
