import { createBrowserHistory } from 'history';

import { runInDevelopment } from './utils';

export const history = createBrowserHistory();

void runInDevelopment?.(() => {
  history.listen(console.info);
  window.__history = history;
});
